<script>
import { global } from './utils'
export default {
  name: 'KarolinafundProjects',
  props: {
    limit: Number,
  },
  data() {
    return {
      global,
      errors: {},
      loadings: {},
      projects: [],
      resizeObserver: null,
    }
  },
  methods: {
    setError(name) {
      this.$set(this.errors, name, true)
    },
    clearError(name) {
      this.$set(this.errors, name, false)
    },
    setLoading(name) {
      this.$set(this.loadings, name, true)
    },
    clearLoading(name) {
      this.$set(this.loadings, name, false)
    },
    getKarolinafundProjectsEndpoint() {
      this.setLoading('getKarolinafundProjectsEndpoint')
      this.clearError('getKarolinafundProjectsEndpoint')
      this.axios
        .get(
          this.$root.karolinafundApiUrl +
            '/agitator/v2/campaigns/preview/en/?stages=2&limit=' +
            this.limit
        )
        .then((response) => {
          this.global.debug(response.data)
          this.projects = JSON.parse(JSON.stringify(response.data.campaigns))
        })
        .catch((error) => {
          this.global.debug(error)
          this.setError('getKarolinafundProjectsEndpoint')
          this.clearLoading('getKarolinafundProjectsEndpoint')
        })
        .finally(() => this.clearLoading('getKarolinafundProjectsEndpoint'))
    },
    getImageSrc(path, width, height) {
      return path
        ? this.global.getKarolinafundCdnImage(path, width, height)
        : null
    },
    getDaysLeft(deadline) {
      return this.getDifferenceInDays(deadline)
    },
    getHoursLeft(deadline) {
      return this.getDifferenceInHours(deadline)
    },
    getDifferenceInDays(end) {
      const now = new Date()
      return this.global.getDaysDifference(now.getTime(), end * 1000)
    },
    getDifferenceInHours(end) {
      const now = new Date()
      return this.global.getHoursDifference(now.getTime(), end * 1000)
    },
    getFundedPercent(project) {
      return Math.ceil((project.funding_reached / project.min_goal) * 100)
    },
    onResize() {
      this.fetchProjects()
    },
    fetchProjects() {
      if (
        this.projects.length === 0 &&
        this.$refs.rootNode.clientHeight > 0 &&
        !this.isGetKarolinafundProjectsLoading
      ) {
        this.getKarolinafundProjectsEndpoint()
      }
    },
  },
  computed: {
    isGetKarolinafundProjectsLoading() {
      return (
        this.loadings['getKarolinafundProjectsEndpoint'] &&
        this.loadings['getKarolinafundProjectsEndpoint'] === true
      )
    },
  },
  mounted() {
    try {
      this.resizeObserver = new ResizeObserver(this.fetchProjects)
      this.resizeObserver.observe(this.$refs.rootNode)
    } catch (e) {
      this.$nextTick(() => {
        this.fetchProjects()
      })
    }
  },
  beforeDestroy() {
    this.resizeObserver.unobserve(this.$refs.myElement)
  },
}
</script>

<style scoped lang="scss"></style>
